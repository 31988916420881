import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"

export default function ContactPage(props) {
  const { contactpage } = props.data

  console.log(contactpage)
  return (
    <Layout {...contactpage}>
      {contactpage.blocks.map((block) => {
        console.log("BLOCK", block)
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...componentProps} />
      })}
    </Layout>
  )
}

export const query = graphql`
  {
    contactpage {
      id
      blocks: content {
        id
        blocktype
        ...HomepageProductListContent
      }
    }
  }
`
